/* eslint-disable */
import {ERROR_CODE, STAGE} from "src/constants/enums";

export const PAGE_NAMES = {
    HOME: '',
}

export const PAGE_PATHS = {
    HOME: '/',
    DOMAINS: '/domains',
    DOMAIN_VIEW: '/domains/:domainId',
    USERS: '/domains/:domainId/users',
    USER_DETAILS: '/domains/:domainId/users/:userId',
    PROFILES: '/domains/:domainId/profiles',
    TRD: '/domains/:domainId/trd',
    TRD_CONTACT_DRIVER: '/domains/:domainId/trd/driver',
    TRD_REASON_FOR_CONTACT: '/domains/:domainId/trd/reason',
    TRD_TYPE: '/domains/:domainId/trd/type',
    PROFILE_VIEW_WITH_PERMISSIONS_MANAGEMENT: '/domains/:domainId/profiles/:profileId/permissions',
    CASE_QUEUES: '/domains/:domainId/caseQueues',
    QUEUE_VIEW_WITH_USER_MANAGEMENT: '/domains/:domainId/caseQueues/:queueId',
    USER_GROUP_VIEW_WITH_USER_MANAGEMENT: '/domains/:domainId/userGroups/:userGroupId',
    FAILURE_PAGE: '/error/:errorCode',
    USER_GROUP:  '/domains/:domainId/userGroups',
    BULK_CASE_MANAGEMENT:  '/domains/:domainId/bulkCaseManagement',
};

export const API_ENDPOINTS = {
    CREATE_OR_UPDATE_PROFILE: '/api/authoritydataservice/create-or-update-profile',
    CREATE_OR_UPDATE_PROFILE_PERMISSIONS: '/api/authoritydataservice/create-or-update-profile-permissions',
    CREATE_OR_UPDATE_QUEUE: '/api/authoritydataservice/create-or-update-queue',
    CREATE_OR_UPDATE_USER: '/api/authoritydataservice/create-or-update-user',
    CREATE_OR_UPDATE_USER_GROUP: '/api/authoritydataservice/create-or-update-usergroup',
    GET_PROFILE_PERMISSIONS: '/api/authoritydataservice/get-profile-permissions',
    GET_USERS: '/api/authoritydataservice/get-users',
    GET_QUEUES: '/api/authoritydataservice/get-queues',
    GET_USER_DETAILS: '/api/authoritydataservice/get-user-details',
    GET_PROFILES: '/api/authoritydataservice/get-profiles',
    SEARCH_EMPLOYEE: '/api/authoritydataservice/search-employee',
    GET_USER_GROUPS: '/api/authoritydataservice/get-user-groups',
    GET_ASSET: '/api/assetdataservice/get-asset',
    CREATE_ASSET: '/api/assetdataservice/create-asset',
    UPDATE_ASSET: '/api/assetdataservice/update-asset',
    KATAL_LOGGER: '/log',
    CREATE_BULK_OPERATION: '/api/v2/create-bulk-operation',
    GET_BULK_OPERATION_STATUS: '/api/v2/get-bulk-operation-status',
    GET_UPLOAD_ASSET_URL: '/api/v2/get-upload-asset-url'
};


export const GLOBAL_ERROR_MESSAGE_TO_ERROR_CODE: Record<ERROR_CODE, string> = {
    [ERROR_CODE.GENERIC_FAILURE]: '5xx',
};

export interface ExternalURLS {
    INTERNAL_SERVER_ERROR_IMAGE: string;
}

export const EXTERNAL_URLS: Record<STAGE, ExternalURLS> = {
    [STAGE.ALPHA]: {
        INTERNAL_SERVER_ERROR_IMAGE: 'https://admin-console.alpha.cases.pxt.amazon.dev/FAILURE_ERROR_IMAGE.png',
    },
    [STAGE.BETA]: {
        INTERNAL_SERVER_ERROR_IMAGE: 'https://admin-console.beta.cases.pxt.amazon.dev/FAILURE_ERROR_IMAGE.png',
    },
    [STAGE.PROD]: {
        INTERNAL_SERVER_ERROR_IMAGE: 'https://admin-console.prod.cases.pxt.amazon.dev/FAILURE_ERROR_IMAGE.png',
    },

}
