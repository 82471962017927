import {ENTITY_TYPE, SearchFilter} from "src/constants/enums";

export const SearchFields: Record<string, Array<string>> = {
  [ENTITY_TYPE.USER]: [SearchFilter.EMPLOYEE_ID],
  [ENTITY_TYPE.PROFILE]: [SearchFilter.PROFILE_ID],
  [ENTITY_TYPE.PROFILE_PERMISSIONS]: [SearchFilter.FIELD_ID],
  [ENTITY_TYPE.QUEUE]: [SearchFilter.QUEUE_NAME, SearchFilter.QUEUE_ID],
  [ENTITY_TYPE.USER_GROUPS]: [SearchFilter.USER_GROUP_ID],
  [ENTITY_TYPE.TRD_MAPPINGS]: [SearchFilter.DRIVER],
  [ENTITY_TYPE.TRD_TYPE]: [SearchFilter.TRD_TYPE],
  [ENTITY_TYPE.TRD_REASON_FOR_CONTACT]: [SearchFilter.TRD_REASON_FOR_CONTACT],
  [ENTITY_TYPE.TRD_DRIVER]: [SearchFilter.TRD_DRIVER],
  [ENTITY_TYPE.CASE_ENTITY]: [],
};