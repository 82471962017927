import {MessageBannerType} from "@amzn/stencil-react-components/message-banner";

export const DEFAULT_PAGE_TITLE = 'PXT Case Management Admin Console';
export const EXPORT_DATA_IN_CSV = 'Export Data in CSV';
export const BULK_DROPDOWN_NAME = 'Bulk Actions';
export const DEFAULT_DROPDOWN_NAME = 'Actions';
export const DEFAULT_FILE_NAME = 'table data';
export const CREATE = 'Create';
export const ADD_USERS = 'Add Users';
export const REMOVE_USERS = 'Remove Users';
export const UPDATE = 'Update';
export const CREATE_OR_UPDATE = 'Create or Update';
export const DELETE = 'Delete'
export const ADD_USERS_TO_QUEUES = 'Add Users To Queues';
export const REMOVE_USERS_FROM_QUEUES = 'Remove Users From Queues';
export const ADD_USERS_TO_GROUPS = 'Add Users To Groups';
export const REMOVE_USERS_FROM_GROUPS = 'Remove Users From Groups';
export const DEFAULT_REQUEST_PAGE_SIZE = 1000;

export const DEFAULT_DISPLAY_PAGE_SIZE = 10;

export const INITIAL_PAGE_NUMBER = 1;
export const EMPLOYEE_ID_SEPARATOR  = ';';

export const PXTCMS_ONLY_DOMAINS = ['ATP', 'BST', 'BXT_SE', 'COE', 'DEPARTURES', 'EMP_APPEAL', 'MOBILITY', 'NJS', 'TEST_DOMAIN', 'GLOBAL', 'COMP_HUB', 'BGC', 'WA', 'UNEMPLOYMENT', 'CAREER_AMBASSADOR', 'DT', 'APPEALS_FEEDBACK', 'INTERVENTIONS'];
const PANO_DEPENDENT_DOMAINS = ['CTK', 'BALI', 'DALI', 'GWA', 'MHLS', 'OPSHR', 'HRP', 'CC'];
// TODO: remove once we are able to fetch authorized domains for auth'd user
export const DOMAINS = [...PANO_DEPENDENT_DOMAINS, ...PXTCMS_ONLY_DOMAINS].sort();
export const PHONE_TOOL = `https://phonetool.amazon.com/users/`;
export const USER_DETAILS_TABS = ['Queues', 'Groups'];
export const USER_CREATION_TABS = ['Search', 'Manual'];
export const FAILURE_ERROR_IMAGE_TEXT = 'Internal Server Failure';
export const CREATE_QUEUE_SUCCESS_MESSAGE = 'Queues Created Successfully';
export const ADD_USER_TO_QUEUE_SUCCESS_MESSAGE = 'Users Added Successfully to the Queues';
export const REMOVE_USER_FROM_QUEUE_SUCCESS_MESSAGE = 'Users Remove Successfully from the Queues';
export const UPDATE_QUEUE_SUCCESS_MESSAGE = 'Queues Updated Successfully';
export const BULK_UPDATE_DRIVERS_SUCCESS_MESSAGE = 'Drivers upload File Processed Successfully';
export const BULK_PROCESS_USER_GROUP_SUCCESS_MESSAGE = 'User Groups File Processed Successfully';
export const USER_SUCCESS_MESSAGE = 'Users Created Successfully';
export const PROFILE_SUCCESS_MESSAGE = 'Profiles Created Successfully';
export const BULK_OPERATION_SUCCESS_MESSAGE = 'Bulk Operation Created Successfully';
export const BULK_OPERATION_FAILURE_MESSAGE = 'Bulk Operation Failed, Please try again';
export const QUEUE_BULK_PAGE_NAME = 'Bulk Queue Create Or Update';
export const CREATE_BULK_QUEUE_PAGE_NAME = 'Bulk Create Queue';
export const UPDATE_BULK_QUEUE_PAGE_NAME = 'Bulk Update Queue';
export const ADD_USER_TO_QUEUE_BULK_PAGE_NAME = 'Bulk Add Users To Queue';
export const REMOVE_USER_TO_QUEUE_BULK_PAGE_NAME = 'Bulk Remove Users from Queue';
export const USER_BULK_PAGE_NAME = 'Bulk User Create Or Update';
export const UPLOAD_BULK_DRIVER  = 'Bulk Create Or Update Drivers';
export const DELETE_BULK_DRIVER = 'Bulk Delete Drivers';
export const PROFILE_BULK_PAGE_NAME = 'Bulk Profile Create Or Update';
export const CASE_ENTITY_BULK_PAGE_NAME = 'Bulk Case Entity Create Or Update';
export const USER_GROUP_BULK_PAGE_NAME = 'Bulk User Group Create Or Update';
export const CREATE_USER_GROUP_BULK_PAGE_NAME = 'Bulk Create User Group';
export const UPDATE_USER_GROUP_BULK_PAGE_NAME = 'Bulk Update User Group';
export const ADD_USER_TO_GROUP_BULK_PAGE_NAME = 'Bulk Add Users to Group';
export const REMOVE_USER_TO_GROUP_BULK_PAGE_NAME = 'Bulk Remove Users from Group';
export const DEFAULT_TEMPLATE_FILE_NAME = 'sample_template.csv';
export const PROFILE_TEMPLATE_FILE_NAME = 'create_or_update_profiles_template.csv';
export const BULK_TEMPLATE_FILE_NAME = 'bulk_template.csv';
export const QUEUE_TEMPLATE_FILE_NAME = 'create_or_update_queues_template.csv';
export const GROUP_TEMPLATE_FILE_NAME = 'create_or_update_user_groups_template.csv';
export const BULK_PAGE_TOOLTIP_TEXT = 'Last column for entity ID can be omitted in case of creation.';
export const CREATE_PROFILE_PERMISSION_VALIDATION_ALERT = 'Please ensure Field Id follows camelCase conventions';
export const CREATE_PROFILE_PERMISSION_VALIDATION_FOOTER = 'Field name must be camelCase and contain no numbers or special characters';
export const ERROR_CODE_4XX = /^4\d{2}$/;
export const GLOBAL = 'GLOBAL';
export const CREATE_CASE = 'Create Case';
export const UPDATE_CASE = 'Update Case';
export const ITEM_DOES_NOT_EXIST = "Item does not exist";

export interface MessageBannerDetails {
    messageBannerText?: string,
    messageBannerType?: MessageBannerType.Success | MessageBannerType.Error,
    messageBannerAutoDismiss?: number
}

export const SUCCESS_MSG_BANNER_AUTO_DISMISS_TIME = 3000
export const ERROR_MSG_BANNER_AUTO_DISMISS_TIME = 5000
export const INFO_MSG_BANNER_AUTO_DISMISS_TIME = 10000

