//TRD interface
export interface ITRDState {
    selectedType: string;
    selectedCountry: string;
    selectedDriver: string;
    selectedReason: string;
    typeList: string[];
    reasonList: string[];
    driverList: string[];
    countryList: string[];
}

//default TRD interface values
export const DefaultTRDState: ITRDState = {
    selectedType: "",
    selectedCountry: "",
    selectedDriver: "",
    selectedReason: "",
    typeList: [],
    reasonList: [],
    driverList: [],
    countryList: ["USA", "AUS", "DEU", "NOR", "TUR", "NLD", "TWN", "IND", "PHL", "NZL", "JPN", 
    "HKG", "ROU", "MYS", "ISR", "LUX", "PRT", "FIN", "CAN", "ITA", "SWE", "CHN", "IDN", "POL", 
    "CZE", "ZAF", "SVK", "ESP", "GBR", "IRL", "CRI", "THA", "VNM", "KOR", "AUT", "BEL", "FRA", "SGP"]
};

export interface TRDFields {
    type: string,
    reason: string,
    driver: string,
}

const TRD_ENABLED_DOMAINS: string[] = ["MHLS"];

export const isTRDEnabledDomain = (domainId: string) => {
    return TRD_ENABLED_DOMAINS.includes(domainId);
}


export const getDomainSpecificTRDFieldNames = (domain: string): TRDFields => {

    switch (domain) {
        case "MHLS":
            return {
                type: "Type",
                reason: "Reason_for_Contact__c",
                driver: "contactDriver"
            } as TRDFields

        case "OPSHR":
            return {
                type: "Type",
                reason: "Reason_for_Contact__c",
                driver: "contactDriver"
            } as TRDFields

        default:
            return {
                type: "Type",
                reason: "Reason",
                driver: "Driver"
            } as TRDFields

    }

}

export const getDriverFilterConfig = (domainId: string) => {

    switch (domainId) {
        case "MHLS":
            return ([
                { key: "selectedType", keyList: "typeList", label: "type", required: true },
                { key: "selectedCountry", keyList: "countryList", label: "country", required: true }
            ]);

        case "OPSHR":
            return ([
                { key: "selectedType", keyList: "typeList", label: "type", required: true },
                { key: "selectedReason", keyList: "reasonList", label: "reason for contact", required: true },
                { key: "selectedCountry", keyList: "countryList", label: "country", required: true }
            ]);

        default:
            return ([
                { key: "selectedType", keyList: "typeList", label: "type", required: true },
                { key: "selectedCountry", keyList: "countryList", label: "country", required: true }
            ]);
    }

}

export const getReasonForContactFilterConfig = (domainId: string) => {

    switch (domainId) {
        case "MHLS":
            return ([
                { key: "selectedType", keyList: "typeList", label: "type", required: true }            ]);

        default:
            return ([
                { key: "selectedType", keyList: "typeList", label: "type", required: true },
                { key: "selectedCountry", keyList: "countryList", label: "country", required: false }
            ]);
    }

}


export const getRequiredDriverTableFieldsBasedOnDomain = (domainId: string) => {

    switch (domainId) {
        case "MHLS":
            return (
                [
                    { header: "Type", accessor: "type" },
                    { header: "Contact Driver", accessor: "driver" },
                    { header: "Country", accessor: "country" }
                ]
            );

        case "OPSHR":
            return ([
                { header: "Type", accessor: "type" },
                { header: "Reason for Contact", accessor: "reason" },
                { header: "Contact Driver", accessor: "driver" },
                { header: "Country", accessor: "country" }
            ]);

        default:
            return ([
                { header: "Type", accessor: "type" },
                { header: "Reason for Contact", accessor: "reason" },
                { header: "Contact Driver", accessor: "driver" },
                { header: "Country", accessor: "country" }
            ]);
    }

}

export const getRequiredReasonForContactsTableFieldsBasedOnDomain = (domainId: string) => {

    switch (domainId) {
        case "MHLS":
            return (
                [
                    { header: "Type", accessor: "type" },
                    { header: "Reason for Contact", accessor: "reason" },
                ]
            );

        default:
            return ([
                { header: "Type", accessor: "type" },
                { header: "Reason for Contact", accessor: "reason" },
                { header: "Country", accessor: "country" }
            ]);
    }

}

export const getRequiredTypeTableFieldsBasedOnDomain = (domainId: string) => {

    switch (domainId) {
        case "MHLS":
            return (
                [
                    { header: "Type", accessor: "type" }
                ]
            );

        default:
            return ([
                { header: "Type", accessor: "type" },
                { header: "Country", accessor: "country" }
            ]);
    }

}

export const listTableDriversDataBasedOnDomain = (trdState: ITRDState, domainId: string, filteredDrivers: string[]) => {
    //refer the accessor values from getRequiredTableFieldsBasedOnDomain()
    let tableData;
    switch (domainId) {
        case "MHLS":
            return filteredDrivers.map(driver => ({
                type: trdState?.selectedType,
                country: trdState?.selectedCountry,
                driver: driver
            }));

        case "OPSHR":
            return filteredDrivers.map(driver => ({
                type: trdState?.selectedType,
                reason: trdState?.selectedReason,
                country: trdState?.selectedCountry,
                driver: driver
            }));

        default:
            return filteredDrivers.map(driver => ({
                type: trdState?.selectedType,
                reason: trdState?.selectedReason,
                country: trdState?.selectedCountry,
                driver: driver
            }));

    }

}

export const listTableReasonForContactsDataBasedOnDomain = (trdState: ITRDState, domainId: string, filteredReasonForContacts: string[]) => {
    //refer the accessor values from getRequiredTableFieldsBasedOnDomain()
    let tableData;
    switch (domainId) {
        case "MHLS":
            return filteredReasonForContacts.map(reason => ({
                type: trdState?.selectedType,
                reason: reason
            }));

        default:
            return filteredReasonForContacts.map(reason => ({
                type: trdState?.selectedType,
                reason: reason,
                country: trdState?.selectedCountry
        }));

    }

}

export const  listTableTypesDataBasedOnDomain = (trdState: ITRDState, domainId: string, filteredTypes: string[]) => {
    //refer the accessor values from getRequiredTableFieldsBasedOnDomain()
    let tableData;
    switch (domainId) {
        case "MHLS":
            return filteredTypes.map(type => ({
                type: type,
                reason: trdState?.selectedReason,
            }));

        default:
            return filteredTypes.map(type => ({
                type: type,
                country: trdState?.selectedCountry
        }));

    }

}

//Fetches TRD type assetId key based on domain
export const getTypeAssetIdBasedOnDomain = (domainId: string): string => {
    const getTRDFields = getDomainSpecificTRDFieldNames(domainId);
    return getTRDFields.type;
}

//Fetches TRD reason assetId key based on domain
export const getReasonAssetIdBasedOnDomain = (trdState: ITRDState, domainId: string): string => {
    const getTRDFields = getDomainSpecificTRDFieldNames(domainId);
    switch (domainId) {
        case "MHLS":
            return `${getTRDFields.reason}-${getTRDFields.type}:${trdState.selectedType}`

        case "OPSHR":
            return `${getTRDFields.reason}-${getTRDFields.type}:${trdState.selectedType}`

        default:
            return `${getTRDFields.reason}-${getTRDFields.type}:${trdState.selectedType}`

    }
}

//Fetches TRD driver assetId key based on domain
export const getDriverAssetIdBasedOnDomain = (trdState: ITRDState, domainId: string): string => {
    const getTRDFields = getDomainSpecificTRDFieldNames(domainId);
    switch (domainId) {
        case "MHLS":
            return `${getTRDFields.driver}-${getTRDFields.type}:${trdState.selectedType} ${trdState.selectedCountry}`

        case "OPSHR":
            return `${getTRDFields.driver}-${getTRDFields.reason}:${trdState.selectedReason}`

        default:
            return `${getTRDFields.driver}-${getTRDFields.reason}:${trdState.selectedReason}`

    }
}
